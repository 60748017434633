import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Paladin',
	portrait: new URL('/img/portraits/PaladinPortrait.gif', import.meta.url).href,
	firstNames: [
		'Antillia',
		'Elayne',
		'Elaina',
		'Elyn',
		'Gerada',
		'Illine',
		'Katra',
		'Katrine',
		'Kayt',
		'Kryste',
		'Mariel',
		'Meyarl',
		'Rytilla',
		'Satrina',
		'Ursia',
		'Vandia',
		'Dian',
		'Kina',
		'Phyllia',
		'Kira',
		'Jupithra',
		'Ethena',
		'Clarina',
		'Dyevanna',
		'Jellara',
		'Wellona',
		'Insira',
		'Tesseryn',
		'Velshana',
	],
	lastNames: [
		'Brightsoul',
		'Holyblade',
		'Nobleheart',
		'of the Swift Blade',
		'Stronghelm',
		'the Crusader',
		'the Proud',
		'the Saint',
		'the Righteous',
		'the Vigiliant',
		'the Redeemer',
		'Clearmind',
		'the Pious',
		'the Vindicator',
		'of Divine Might',
		'the Inspired',
		', Arm of Dauros',
		'of Clear Judgement',
	],
}

export default Object.freeze(hero)
