import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Gnome',
	portrait: new URL('/img/portraits/GnomePortrait.gif', import.meta.url).href,
	firstNames: [
		'Elmut',
		'Errman',
		'Pender',
		'Punto',
		'Ranz',
		'Udi',
		'Unther',
		'Udi',
		'Pilo',
		'Flip',
		'Iltar',
		'Punger',
		'Polit',
		'Fino',
		'Wip',
		'Prang',
		'Dalbur',
		'Rudd',
		'Gugo',
		'Nobb',
		'Quaut',
		'Nerni',
		'Ploji',
		'Joop',
		'Urgan',
		'Olff',
		'Mibbi',
		'Fud',
		'Narph',
		'Voo',
		'Yethro',
		'Bleejo',
		'Klem',
		'Jed',
		'Limay',
		'Zook',
		'Jengles',
		'Sklo',
		'Bimjo',
	],
	lastNames: ['Bopo', 'Klup', 'Drep', 'Quig', 'Crep'],
}

export default Object.freeze(hero)
