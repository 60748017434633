type PortraitProps = {
	portrait: string
	type: string
	onClick: CallableFunction
}

export default function Portrait({ portrait, type, onClick }: PortraitProps) {
	return (
		<button
			onClick={() => {
				onClick(type)
			}}
		>
			<img className="portrait" src={portrait} alt={type} title={type} />
		</button>
	)
}
