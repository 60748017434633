import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Priestess',
	portrait: new URL('/img/portraits/PriestessPortrait.gif', import.meta.url).href,
	title: 'Sister',
	firstNames: [
		'Blackblood',
		'Daedra',
		'Darksoul',
		'Dorota',
		'Kquellest',
		'Gyllest',
		'Lifesbane',
		'Verdasha',
		'Redblood',
		'Shadowfriend',
		'Soulstealer',
		'Spiritvoid',
		'Dread',
		'Voidra',
		'Yvella',
		'Deathluste',
		'Shadespawn',
		'Necros',
		'Omegan',
		'Jurellun',
		'Deepnight',
		'Karillum',
	],
}

export default Object.freeze(hero)
