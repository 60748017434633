import { useState } from 'react'
import Portrait from './components/Portrait'
import heroes from './heroes'

export function App() {
	const [name, setName] = useState('')
	const [type, setType] = useState('')

	const generateAnyName = (type: string): void => {
		const firstNames: Array<string> = []
		const lastNames: Array<string> = []

		heroes.forEach((hero) => {
			hero.firstNames.forEach((firstName) => {
				firstNames.push(firstName)
			})

			if (hero.lastNames !== undefined) {
				hero.lastNames.forEach((lastName) => {
					lastNames.push(lastName)
				})
			}
		})

		setType(type)
		setName(createName(firstNames, lastNames))
	}

	const generateTypeName = (heroType: string): void => {
		const hero = heroes.find((hero) => {
			return hero.type === heroType
		})

		if (hero === undefined) {
			console.log('could not find a hero with that type')
			return
		}

		setType(hero.type)
		setName(createName(hero.firstNames, hero.lastNames, hero.title))
	}

	const createName = (firstNames: Array<string>, lastNames: Array<string>, title?: string): string => {
		let name = []

		if (title !== undefined) {
			name.push(title)
		}

		name.push(firstNames[Math.floor(Math.random() * firstNames.length)])

		if (lastNames !== undefined) {
			name.push(lastNames[Math.floor(Math.random() * lastNames.length)])
		}

		return name.join(' ')
	}

	return (
		<div className="main">
			<div className="wrapper">
				<h1 className="hidden">Majesty name generator</h1>
				<img className="logo" src={new URL('/img/Logo.jpg', import.meta.url).href} alt="Majesty logo" />
				<span className="instruction">Click a portrait to generate a hero name!</span>

				<div className="portraits">
					{heroes.map((hero) => (
						<Portrait
							key={hero.type}
							type={hero.type}
							portrait={hero.portrait}
							onClick={generateTypeName}
						/>
					))}
					<Portrait
						type={'Random'}
						portrait={new URL('/img/portraits/HooliganPortrait.gif', import.meta.url).href}
						onClick={generateAnyName}
					/>
				</div>
				{name && <span className="font-medieval text-4xl pt-10">{name}</span>}
				{type && <span className="font-medieval text-xl pt-2">{type}</span>}
			</div>
		</div>
	)
}
