import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Elf',
	portrait: new URL('/img/portraits/ElfPortrait.gif', import.meta.url).href,
	firstNames: [
		'Ellquino',
		'Fillian',
		'Herrnava',
		'Olliluqus',
		'Perrilon',
		'Perthus',
		'Tolleran',
		'Vinneran',
		'Svenian',
		'Vantilin',
		'Daerion',
		'Quillion',
		'Quellid',
		'Felixe',
		'Indron',
		'Zirdel',
		'Rellinus',
		'Arrius',
		'Kwellyn',
		'Maravus',
		'Skeene',
		'Milliyan',
		'Questus',
		'Kwinnil',
		'Yestous',
		'Pallian',
		'Byrid',
		'Jessava',
		'Mintan',
		'Arrius',
		'Seftillan',
		'Weffeniss',
	],
	lastNames: [
		'Bluehair',
		'Goldensong',
		'Heartywine',
		'Keenwager',
		'Lightfoot',
		'Longshot',
		'Nimblefoot',
		'Silvertongue',
		'Smoothhands',
		'Sparklecharm',
		'Speedbolt',
		'Sweetwine',
		'Tendereyes',
		'the Debauched',
		'Ticklelaugh',
		'Twinkleyes',
		'Velvetvoice',
		'Slenderbones',
		'Luckydie',
		'Rollsure',
		'Fleetbow',
		'Silkskin',
		'Surefortune',
		'Laughright',
		'Quicklute',
		'Strongbrew',
		'Hoistafew',
		'the Charmer',
		'Restwell',
		'Goododds',
		'Diespinner',
	],
}

export default Object.freeze(hero)
