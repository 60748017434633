import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Warrior',
	portrait: new URL('/img/portraits/WarriorPortrait.gif', import.meta.url).href,
	title: 'Sir',
	firstNames: [
		'Aragonder',
		'Argoth',
		'Baramar',
		'Beauregard',
		'Billam',
		'Bren',
		'Calindor',
		'Duric',
		'Eevor',
		'Eldan',
		'Finnelhan',
		'Lucas',
		'Lumathor',
		'Mithron',
		'Osian',
		'Palador',
		'Persis',
		'Promethion',
		'Sarion',
		'Stal',
		'Stone',
		'Sturm',
		'Sylvarion',
		'Tremon',
		'Valian',
		'Willem',
		'Duvane',
		'Nelton',
		'Dremenon',
		'Norvus',
		'Bhyll',
		'Seevin',
		'Chomdel',
		'Mezurak',
		'Ironside',
		'Algin',
		'Dronn',
		'Vence',
		'Kethon',
		'Kraye',
		'Brador',
		'Rendell',
		'Telenar',
		'Spalwin',
		'Gurdain',
		'Gerwyn',
		'Voyt',
		'Trevian',
		'Vorten',
		'Wodenthal',
	],
	lastNames: [
		'Broadedge',
		'Headlopper',
		'Keenswing',
		'Knightly',
		'of the Bloody Blade',
		'Blade',
		'Sharpwit',
		'Silveredge',
		'Solidfist',
		', Son of Kolar',
		'Stoneblade',
		'Strongwill',
		'Swiftblade',
		'the Battlemaster',
		'the Blue',
		'the Courageous',
		'the Loyal',
		'the Slicer',
		'the Victorious',
		'the Worthy',
		'of Longridge',
		'Vunderson',
		'Quickparry',
		', the Fairly Swift',
		'the Mighty',
		'of North Glen',
		"of Kranal's Creek",
	],
}

export default Object.freeze(hero)
