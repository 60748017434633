import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Wizard',
	portrait: new URL('/img/portraits/WizardPortrait.gif', import.meta.url).href,
	firstNames: [
		'Amelon',
		'Borador',
		'Fandall',
		'Garemond',
		'Horece',
		'Kalderon',
		'Orlin',
		'Phyrlin',
		'Ravandel',
		'Rulathal',
		'Saramen',
		'Shelazar',
		'Stremond',
		'Thelocorus',
		'Throthgar',
		'Merlan',
		'Saureth',
		'Vandar',
		'Argoth',
		'Amelorn',
		'Menothras',
		'Torvin',
		'Rythor',
		'Venn',
		'Byuell',
		'Phenn',
		'Tylar',
		'Rhyax',
		'Ghenni',
		'Trethen',
		'Xxaxx',
		'Voster',
		'Zagadu',
		'Rendi',
		'Omarian',
		'Maskedor',
		'Mozadus',
		'Huranthell',
		'Pyrinn',
		'Viscoldius',
	],
	lastNames: [
		', Lord of Wisdom',
		'of the Mysteries',
		'of the Tower',
		'the Aged',
		'the Alchemist',
		'the Arcane',
		'the Battlemage',
		'the Insightful',
		'the Knowing',
		'the Mumbler',
		'the Omniscient',
		'the Sage',
		'the Summoner',
		'the Wise',
		', Master of the Arcane',
		'the Skilled',
		'the Able',
		'the Inquisitive',
		'the Demented',
		'the Conjurer',
		'of Squagin Hill',
		'of Rethylholm',
		'the Sure',
		', Master of the Unknowable',
		', Student of Nivo',
		'the Reasonably Lucid',
		', Master of Magiks',
		', Wizard For Hire',
		'of the Putrid Robes',
		'of Thalsdale',
		'Spellworthy',
		'the Clever',
		'the Energetic',
		'the Uncertain',
		'of the Purest Energies',
		'the Lesser',
		'the Profound',
		'the Amazing',
		'the Shambler',
		'the Mystic',
	],
}

export default Object.freeze(hero)
