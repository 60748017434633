import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Solarus',
	portrait: new URL('/img/portraits/SolarusPortrait.gif', import.meta.url).href,
	firstNames: [
		'Celestia',
		'Celestra',
		'Celestriel',
		'Charell',
		'Eidra',
		'Flaire',
		'Flamia',
		'Ignitia',
		'Illumia',
		'Infernia',
		'Kiva',
		'Lumina',
		'Morne',
		'Nova',
		'Radiella',
		'Solaria',
		'Staria',
		'Stella',
		'Traddi',
		'Hellun',
		'Yewvi',
		'Embere',
		'Glowri',
		'Jupista',
		'Hydrella',
		'Arydia',
	],
	lastNames: [
		', Mistress of Light',
		'Daybreaker',
		'Daystar',
		'Evershine',
		'Flamewielder',
		'Goldenhue',
		'Nightsbane',
		'of Bright Meadows',
		'Skyblaze',
		'Skyglow',
		'Solarshine',
		'Starchaser',
		'Sunbringer',
		'Suncaster',
		'Sundrencher',
		'Gleamweaver',
		'Torchdancer',
		'Dayblinder',
		'Firesong',
		'Warmtouch',
		'Bronzebrow',
		', the Quick',
		', the Firey',
		'Blazeheart',
	],
}

export default Object.freeze(hero)
