import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Cultist',
	portrait: new URL('/img/portraits/CultistPortrait.gif', import.meta.url).href,
	firstNames: [
		'Beautiful',
		'Happy',
		'Bold',
		'Vibrant',
		'Luminous',
		'Joyous',
		'Garish',
		'Pretty',
		'Gleeful',
		'Howling',
		'Thriving',
		'Luxurious',
		'Piquant',
		'Placid',
		'Pleasant',
		'Purple',
		'Remarkable',
		'Rosy',
		'Gleaming',
		'Savory',
		'Shiny',
		'Striped',
		'Tasty',
		'Radiant',
		'Tranquil',
		'Luscious',
		'Robust',
		'Zesty',
		'Wonderful',
		'Yellow',
		'Puce',
		'Glowing',
		'Ecstatic',
		'Lavender',
		'Vivid',
		'Perky',
	],
	lastNames: [
		'Beetle',
		'Ant',
		'Bone',
		'Bush',
		'Cloud',
		'Cow',
		'Daisy',
		'Flower',
		'Forest',
		'Grass',
		'Heart',
		'Heavens',
		'Loam',
		'Moon',
		'Meadow',
		'Pond',
		'Pony',
		'Puddle',
		'Rain',
		'Rainbow',
		'Rock',
		'Sea',
		'Shrub',
		'Snake',
		'Soil',
		'Glade',
		'Stone',
		'Tree',
		'Weed',
		'Wound',
		'Bunny',
		'Stream',
		'Boulder',
	],
}

export default Object.freeze(hero)
