import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Adept',
	portrait: new URL('/img/portraits/AdeptPortrait.gif', import.meta.url).href,
	firstNames: [
		'Clovis',
		'Elris',
		'Jadian',
		'Lucius',
		'Lunos',
		'Luthiel',
		'Slennar',
		'Skylar',
		'Tephan',
		'Varl',
		'Zed',
		'Zellin',
		'Veltur',
		'Zenn',
		'Cykes',
		'Hurane',
		'Nylos',
		'Zolam',
		'Elntir',
		'Arthum',
		'Trelan',
		'Lunal',
		'Jupion',
		'Jadel',
		'Lumath',
		'Breece',
		'Vint',
		'Sallun',
		'Gayle',
		'Rhast',
		'Varnen',
		'Zeele',
		'Bevid',
		'Zeth',
		'Saphune',
	],
	lastNames: [
		'Daysbane',
		'Fleetfoot',
		'Greffen',
		'Longstrider',
		'Moongaze',
		'Nightshade',
		'of the Wind',
		'Palemoon',
		'Sprintwell',
		'Sternwill',
		'Swiftstaff',
		'the Chosen',
		'the Quick',
		'the Smooth',
		'Windwalker',
		'Speedshade',
		'Nightrush',
		'the Duskrider',
		'Nightrunner',
		'of Randell',
		'of North Gil',
		'Lytestep',
		'Fastwake',
		'Moonshadow',
		'the Elder',
		'Briskstep',
		'Brightstreak',
		'Flashheart',
		'Velocitar',
		'Streaksteps',
		'Quickmoon',
		'Dashwell',
	],
}

export default Object.freeze(hero)
