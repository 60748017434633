import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Warrior of Discord',
	portrait: new URL('/img/portraits/WarriorDiscordPortrait.gif', import.meta.url).href,
	firstNames: [
		'Blutas',
		'Brador',
		'Cloetus',
		'Evren',
		'Kilvus',
		'Lerzus',
		'Licham',
		'Loctos',
		'Protactus',
		'Snevv',
		'Shevis',
		'Vagnus',
		'Daevactus',
		'Kaptus',
		'Fracas',
		'Pulvis',
		'Clevus',
		'Convictus',
		'Tacharus',
		'Mildar',
		'Corpus',
		'Ulterion',
		'Manglor',
		'Korrectus',
		'Vicernal',
		'Dissyctus',
		'Harm',
		'Retsal',
		'Chamus',
		'Narmunn',
		'Prondus',
		'Demmagus',
		'Skuttinor',
		'Inciticus',
		'Marcus',
		'Tohlinus',
		'Jadanav',
	],
	lastNames: [
		', Disciple of Chaos',
		', Son of Vagnus',
		'Anarchus',
		'Chaoticus',
		'Conflictus',
		'Insanus',
		'Malignus',
		'Necral',
		'the Abuser',
		'the Anti-Social',
		'the Incoherant',
		'the Ranting',
		'Omnipotus',
		'Smactus',
		'Combaticus',
		'the Confused',
		'Dementius',
		'Schizus',
		'Lunaticus',
		'the Unsound',
		'the Deranged',
		'the Lost',
		'the Naughty',
		'Corruptus',
		'the Hurtful',
		'the Awfully Mad',
		'on the Brink',
		', Servant of Madness',
		'the Obtuse',
		'the Disconnected',
		'the Hand of Bedlam',
		'Pandamonius',
		", Entropy's Servant",
		'Tremmoil',
		'the Random',
	],
}

export default Object.freeze(hero)
