import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Monk',
	portrait: new URL('/img/portraits/MonkPortrait.gif', import.meta.url).href,
	firstNames: [
		'Allyn',
		'Bhen',
		'Kliven',
		'Mithyn',
		'Oki',
		'Om',
		'Pirino',
		'Reth',
		'Shaereth',
		'Tymerus',
		'Omin',
		'Dharmine',
		'Xetu',
		'Mupatha',
		'Urlynn',
		'Garu',
		'Ichni',
		'Ganyo',
		'Tayama',
		'Hagesa',
		'Alpa',
		'Tomeh',
		'Ran-ran',
		'Nivo',
		'Quicho',
		'Po',
		'Juvoni',
		'Kwo Chi',
		'Yaan',
		'Chi Quen',
		'Vevin',
		'Wewan',
		'Agilla',
	],
	lastNames: [
		'the Calm One',
		'the Composed One',
		'the Contemplative One',
		'the Dedicated One',
		'the Devoted One',
		'the Devout One',
		'the Dignified One',
		'the Dreaming One',
		'the Driven One',
		'the Focused One',
		'the Meditative One',
		'the Neat One',
		'the Patient One',
		'the Pensive One',
		'the Pious One',
		'the Placid One',
		'the Quiet One',
		'the Reclusive One',
		'the Serene One',
		'the Solitary One',
		'the Sullen One',
		'the Quick One',
		'the Stoic One',
		'the Stalwart One',
		'the Clever One',
		'the Enlightened One',
		'the Spiritual One',
		'the Reflective One',
		'the Righteous One',
		'the Amused One',
		'the Raucous One',
		'the Silent One',
		'the Peaceful One',
		'the Artistic One',
	],
}

export default Object.freeze(hero)
