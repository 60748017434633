import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Barbarian',
	portrait: new URL('/img/portraits/BarbarianPortrait.gif', import.meta.url).href,
	firstNames: [
		'Urthclub',
		'Graud',
		'Bloud',
		'Brann',
		'Barnus',
		'Rool',
		'Stred',
		'Flaug',
		'Krill',
		'Kornan',
		'Raug',
		'Gren',
		'Tull',
		'Helgur',
		'Hrex',
		'Kholl',
		'Grannur',
		'Durd',
		'Lil-Nub',
		'Mung',
		'Nub',
		'Nugg',
		"H'loog",
		'Thud',
		'Troth',
		'Oon',
		'Turgus',
		'Urdo',
		'Vren',
		'Ullar',
		'Wulf',
		'Grev',
		'Tundur',
		'Raim',
		'Rudgr',
		'Gara',
		'Fargo',
		'Torvan',
		'Groon',
	],
	lastNames: [
		'the Simple',
		'the Naive',
		'the Foolish',
		'Stumpheaver',
		'Swiftaxe',
		'Thikson',
		'Hardbrow',
		'the Untamed',
		'the Savage',
		'of the Wild',
		'Skullslam',
		'Strongclub',
		'Traksmart',
		'the Cleaver',
		'the Club',
		'Grent',
		'the Short',
		'the Lost',
		'the Vindicated',
		'the Large',
		'the Very Large',
		'the Younger',
		'Toothgnasher',
		'Gnash',
		'Heavystep',
		'Shuv',
		'the Conqueror',
		'Bashwright',
		'the Barbarian',
		'the Cutter',
		'of Otherplace',
		'Brushrunner',
		'the Clever',
		'Bigfists',
		'of Few Words',
		'of Downstream',
		'of No Town',
		'Poundwell',
		'the Pummeler',
		'the Lesser',
		'Broadtrunk',
	],
}

export default Object.freeze(hero)
