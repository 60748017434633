import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Ranger',
	portrait: new URL('/img/portraits/RangerPortrait.gif', import.meta.url).href,
	firstNames: [
		'Jupion',
		'Anarien',
		'Avalin',
		'Cenneth',
		'Calerian',
		'Denell',
		'Deorn',
		'Diameth',
		'Durewn',
		'Eldor',
		'Elebrain',
		'Elris',
		'Eterian',
		'Feddeth',
		'Gemellion',
		'Lucius',
		'Mithrian',
		'Onden',
		'Sterling',
		'Sylves',
		'Tendann',
		'Thyriad',
		'Vandon',
		'Velar',
		'Venstar',
		'Zugon',
		'Teeve',
		'Belshaw',
		'Neynos',
		'Praul',
		'Alleran',
		'Camrin',
		'Hendar',
		'Nyphlan',
		'Steth',
		'Ayodon',
		'Kosef',
		'Derrit',
	],
	lastNames: [
		'Sprintwell',
		'Trackcutter',
		'Quickbow',
		'Broadview',
		'Gnarlswood',
		'Huntswell',
		'Longgait',
		'Pathfinder',
		'Ravenwood',
		'the Cartographer',
		'the Explorer',
		'the Frontiersman',
		'the Outlander',
		'the Overlander',
		'the Questing',
		'the Seeker',
		'Trailblazer',
		'Trailchaser',
		'of the Country',
		'Wildmaster',
		'Hilltopper',
		'Overhill',
		'the Steady',
		'the Solitary',
		'of Ten Troll Dell',
		'of Sylvan Knoll',
		'Brookford',
		'of Laughing Brook',
		'of Treldan Wood',
		'of Dark Woods',
		'Bushbreaker',
		'Surescout',
		'the Inquisitive',
		'the Nimble',
		'Lakescouter',
		'Sorefoot',
	],
}

export default Object.freeze(hero)
