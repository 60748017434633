import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Healer',
	portrait: new URL('/img/portraits/HealerPortrait.gif', import.meta.url).href,
	firstNames: [
		'Arah',
		'Arbehra',
		'Chella',
		'Chalia',
		'Drenthi',
		'Elin',
		'Ellusia',
		'Emerah',
		'Firtila',
		'Fandia',
		'Lucia',
		'Nedya',
		'Selenia',
		'Stenphri',
		'Valeria',
		'Saru',
		'Merian',
		'Charity',
		'Exanli',
		'Helin',
		'Feria',
		'Carella',
		'Soothen',
		'Selve',
		'Karess',
		'Gestyele',
		'Jessini',
		'Sheela',
		'Swevess',
		'Yalissa',
		'Wovella',
	],
	lastNames: [
		', Daughter of love',
		'Calmbringer',
		'Coolwater',
		'of the Pond',
		'Peacebearer',
		'Advell',
		'Teylanoll',
		'the Benevolent',
		'the Caring',
		'the Empathic',
		'the Herbalist',
		'the Humane',
		'the Soother',
		'the Tender',
		'the Pacifier',
		'the Sympathetic',
		'the Kind',
		'Warmheart',
		'Healinghands',
		'Lifebringer',
		'Soultender',
		'the Gentle',
		'Kindheart',
	],
}

export default Object.freeze(hero)
