import { Hero } from './types/hero'

import rogue from './heroes/rogue'
import ranger from './heroes/ranger'
import wizard from './heroes/wizard'
import warrior from './heroes/warrior'
import gnome from './heroes/gnome'
import barbarian from './heroes/barbarian'
import healer from './heroes/healer'
import dwarf from './heroes/dwarf'
import paladin from './heroes/paladin'
import warriorDiscord from './heroes/warriorDiscord'
import cultist from './heroes/cultist'
import priestess from './heroes/priestess'
import monk from './heroes/monk'
import elf from './heroes/elf'
import solarus from './heroes/solarus'
import adept from './heroes/adept'

const heroes: Array<Hero> = [
	rogue,
	ranger,
	wizard,
	warrior,
	paladin,
	warriorDiscord,
	barbarian,
	priestess,
	monk,
	solarus,
	adept,
	healer,
	cultist,
	elf,
	dwarf,
	gnome,
]

export default heroes
