import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Rogue',
	portrait: new URL('/img/portraits/RoguePortrait.gif', import.meta.url).href,
	firstNames: [
		'Chaseph',
		'Drave',
		'Genther',
		'Snivler',
		'Iskar',
		'Jethew',
		'Mayvar',
		'Nefrey',
		'Pheral',
		'Pibb',
		'Skytt',
		'Svenix',
		'Vandel',
		'Deneth',
		'Katric',
		'Crik',
		'Loo',
		'Perlo',
		'Vynee',
		'Drevis',
		'Rolloh',
		'Krevin',
		'Klirk',
		'Tamm',
		'Syth',
		'Bykir',
		'Narvin',
		'Skutty',
		'Dwyte',
		'Nidder',
		'Jemmi',
		'Rhyant',
		'Skeev',
		'Gollum',
		'Stott',
		'Beedo',
		'Devit',
		'Runo',
	],
	lastNames: [
		'Goldgrub',
		', Son of Ned',
		'the Lifter',
		'the Acquirer ',
		'the Graverobber ',
		', Son of Filch',
		'Badcredit',
		'Fiftyfingers',
		'Fullpurse',
		'Heckler',
		'of Lower Pharb',
		'of the Shadows',
		'Pilferpocket',
		'Stickyfingers',
		'the Cheap',
		'the Lesser',
		'the Miserly',
		'the Nasty',
		'the Screamer',
		'Tightgold',
		'the Outlaw',
		'the Thief',
		'the Greedy',
		'the Shaft',
		'the Fist',
		'the Weasel',
		'Heavychest',
		'Spendnaught',
		'Sureshot',
		'Neverspend',
		'Tightpockets',
		'Pickright',
		'the Sneaky',
		'Moregold',
		'the Hoarder',
		'the Snitch',
		'the Mercenary',
		'Foulbolt',
		'the Collector',
		'Quickbow',
		'the Bow',
	],
}

export default Object.freeze(hero)
