import { Hero } from './../types/hero'

const hero: Hero = {
	type: 'Dwarf',
	portrait: new URL('/img/portraits/DwarfPortrait.gif', import.meta.url).href,
	firstNames: [
		'Chamic',
		'Filbo',
		'Gedget',
		'Gilbon',
		'Runder',
		'Skyl',
		'Thron',
		'Tinkar',
		'Vengor',
		'Chab',
		'Buldon',
		'Dilvar',
		'Karoc',
		'Fillik',
		'Nerb',
		'Palik',
		'Rendic',
		'Machnak',
		'Nysion',
		'Pillur',
		'Vhram',
		'Forvar',
		'Zemm',
		'Grovin',
		'Molver',
		'Teej',
		'Seltar',
		'Oovento',
		'Gatar',
		'Thendas',
	],
	lastNames: [
		'Amplesweat',
		'Barbender',
		'Barnbuilder',
		'Broadfork',
		'Ditchdigger',
		'Geargrinder',
		'Hammercrush',
		'Ironcutter',
		'Labordale',
		'Redwood',
		'Roundhill',
		'Splitstone',
		'Steelforge',
		'the Diligent',
		'the Squat',
		'Underhill',
		'Callouspaw',
		'Stonesplitter',
		'Rockpounder',
		'Sturdybuild',
		'Barrelmaker',
		'Stoutpull',
		'Leverworth',
		'Hoistwell',
		'Surehammer',
		'Goodtool',
		'Torquewell',
		'Rivetson',
		'the Clever',
		'Sureway',
		'the Reliable',
	],
}

export default Object.freeze(hero)
